import React from "react";
import { Container, Grid, Paper, Box, Divider,Link } from '@mui/material';
import ReportService from "../../services/ReportService"
import type { ReportData} from "../../datamodel/model"
import { Typography } from '@material-ui/core';
import {FileDownloadUtilities} from "../../services/FileDownloadService";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

//https://github.com/floydf/thinking-react-typescript/blob/master/src/components/FilterableProductTable.tsx

interface TableRowProps {
    cat?: string;
    report?: ReportData;
    key: string;
}

class CategoryRow extends React.Component<TableRowProps, {}> {
    constructor(props: TableRowProps){
	super(props);
    }
    render() {
	return <tr><td colSpan={2}>
        <Typography style={{fontSize: "30px", fontWeight: "lighter", textAlign: "left", color:"#5AA3D2", marginLeft:"0px"}}>
        {this.props.cat}
        </Typography>
        </td></tr>;
    }
}

class ReportRow extends React.Component<TableRowProps, {}> {
    constructor(props: TableRowProps){
	super(props);
    }
    render() {
    let path = this.props.report!.path
	return (
	    <tr>
		<td>
        <Link component="button" variant="body2" onClick={() => 
                FileDownloadUtilities.initiateReportFileDownload('prod/sparkdata?route=downloadReport&filetype=xlsm&path=' + path, path.split('/').slice(-1)[0],"application/vnd.ms-excel.sheet.macroEnabled.12;")}>
            {path.split('/').slice(-1)[0]}        
            </Link>
        </td>
	    </tr>
	);
    }
}

interface ReportTableProps {
    reports: ReportData[];
    filterText: string;
    
}

class ReportTable extends React.Component<ReportTableProps, {}> {
    constructor(props: ReportTableProps){
	super(props);
    }
    render() {
	let rows: React.ReactElement<TableRowProps>[] = [];
	let lastCategory = "";
	this.props.reports.forEach( (report: ReportData) => {
	    if (report.path.indexOf(this.props.filterText) === -1 ) {
		return;
	    }
	    if (report.report_category !== lastCategory) {
		rows.push(<CategoryRow cat={report.report_category} key={report.report_category} />);
	    }
	    rows.push(<ReportRow report={report} key={report.path}/>);
	    lastCategory = report.report_category;
	});
	return (
	    <table>
		<tbody>{rows}</tbody>
	    </table>
	);
    }
}

interface SearchBarProps {
    filterText: string;
    onFilterTextInput: (s: string) => void;
}

class SearchBar extends React.Component<SearchBarProps, {}> {
    constructor(props: SearchBarProps){
	super(props);
	this.handleFilterTextInputChange =
	    this.handleFilterTextInputChange.bind(this);
    }
    handleFilterTextInputChange(e: any) {
	this.props.onFilterTextInput(e.target.value);
    }
    render() {
	return (
	    <form>
		<input
		    type="text"
		    placeholder="Search ..."
		    value={this.props.filterText}
		    onChange={this.handleFilterTextInputChange}
		/>
	    </form>
	);
    }
}

export interface FilterableTableProps {
    reports: ReportData[]
}

interface FilterTableState {
    filterText: string;
    expandFAQ:string;
    downloadFAQText:string;
}

export class FilterableTable extends React.Component<FilterableTableProps, FilterTableState > {
    constructor(props: FilterableTableProps){
	super(props);
	this.state = {
	    filterText: "", expandFAQ:"none", downloadFAQText:"Show Downloadable FAQs"
	};
	this.handleFilterTextInput =
	    this.handleFilterTextInput.bind(this);

    }
    toggleDisplay = () => {
        if (this.state.expandFAQ === "none") {
            this.setState({
                expandFAQ: "11",
                filterText:this.state.filterText,
                downloadFAQText:"Hide Downloadable FAQs"
            })
        } else {
            this.setState({
                expandFAQ: "none",
                filterText:this.state.filterText,
                downloadFAQText:"Show Downloadable FAQs"
            })
        }
        console.log(this.state.expandFAQ);
    };
    handleFilterTextInput(filterText: string) {
	console.log("handleFilterTextInput " + filterText);
	this.setState({
	    filterText: filterText,
        expandFAQ:this.state.expandFAQ
	})
    //const [expandFAQ, setExpandFAQ] = React.useState("none");
    }
    

    render() {
	return (
        <div>
        <Grid container spacing={1} style={{}}>
        <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>

        <Grid container justifyContent={"flex-end"} xs = {11} style={{position: "relative", paddingBottom: "0px", paddingTop: "20px"}}>
            <Link component="button" variant="body2" onClick={this.toggleDisplay}>{this.state.downloadFAQText}</Link>
        </Grid>
        
        <Grid container display={{xs:this.state.expandFAQ}} xs = {11} justifyContent={"flex-end"} style={{position: "relative", paddingBottom: "0px", paddingTop: "20px"}}>
                <List>
                    <ListItem disablePadding>
                    <ListItemButton>
                        <Link target="_blank" href="./FAQHowtoRequestAccess.docx" rel="noreferrer" >How to Request Access</Link>
                    </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemButton>
                        <Link target="_blank" href="./FAQUnblockMacros.docx" rel="noreferrer" >How to Unblock Macros</Link>
                    </ListItemButton>
                    </ListItem>
                </List>
        </Grid>
        
        <Grid container spacing={2} item xs = {12} style={{backgroundColor:"", marginLeft: "8rem", marginTop: "5rem", marginBottom: "5rem"}}>
            <Grid item xs = {5} style={{position: "relative", paddingBottom: "40px", paddingTop: "40px"}}>
                <SearchBar
                    filterText={this.state.filterText}
                    onFilterTextInput={this.handleFilterTextInput}
                />
                <ReportTable
                    reports={this.props.reports}
                    filterText={this.state.filterText} 
                />
            </Grid>
        </Grid>
        </Paper>
    
        <Grid item xs={12}>
            <Divider color="black" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "40px", marginTop: "40px", border: "20px"}} />
        </Grid>
        </Grid>
        </div>
	);
    }
}


const DashboardReports: React.FunctionComponent = () => {

    /**
     * States
     */
    const [isLoading, setIsLoading] = React.useState(true); // see if page is loaded already
    const [reportData, setReportData] = React.useState<ReportData[]>([]);


    /**
     * @returns data as a dataframe from restapilambda lambda function on AWS
     */

    const fetchData = async (route: string) => {
        /**
         * promise
         */
        let result = await Promise.all([
            ReportService.getReportListing("getReportListing")
        ])
        //result[0].da_data= result[0].da_data.sort((a:any, b:any) => b.DateTime < a.DateTime ? 1: -1);
        console.log("RESULT FROM PROMISE: ", result[0].data)
        return result
    }

    /**
     * This part is responsible for intial data fetch when loading the page
     */
    React.useEffect(() => {
        const initDataFetch = async () => {
            const res: any[any] = await fetchData("getReportListing");
            setReportData(res[0].data)
        };
        initDataFetch();
    }, []);

    /**
     * This part runs when the page is loading, and data is already stored in the state
     */
    React.useEffect(() => {
        console.log("isLoading",isLoading)
        if (isLoading === true && reportData.length !== 0) {
            console.log("All data is set")
            setIsLoading(false);
        }
        else if (isLoading === true) {
            console.log("Waiting")
        }

    }, [reportData, isLoading]);

    const renderDashBoard = (reportData: ReportData[]) => {
        console.log("in render dashboard")
        return (
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ '& > :not(style)': { m: 1 }, textAlign: "Left", postion: "abolute", opacity: "100%" }}>
                <FilterableTable reports={reportData} />
                </Box>
            </Container>
        );
    };

    return isLoading ? <>Loading...</> : renderDashBoard(reportData);
};


export default DashboardReports;